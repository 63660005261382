<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      class="dialogTable"
      :before-close="handleClose">
    <div>
      <div class="tabsCls" v-if="butType != 2 || butType != 3">
        <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
          <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                       :name="item.value"></el-tab-pane>
        </el-tabs>
      </div>
      <el-row :gutter="10">
        <el-col :span="showListtew ? 4 : 1" :class="['p_r', showListtew ? '' : 'el-col-unfold','leftHeighe']">
          <div :class="['unfoldClassifyBtn', showListtew ? 'el-icon-arrow-left':'el-icon-arrow-right']"
               @click="showListtew = !showListtew"></div>
          <div class="lump" v-show="showListtew">
            <div class="catalogueBox rulesBox">
              <el-table
                  v-loading="columnLoading"
                  :data="catalogueList"
                  ref="catalogueTable"
                  size="small"
                  :show-header=false
                  :height="(butType == 2 || butType == 3) ? 'calc(100vh - 238px)' : 'calc(100vh - 178px)'"
                  class="table" highlight-current-row
                  @current-change="directorySelection">
                <el-table-column prop="total" label="资源鉴定"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="showListtew ? 20 : 24" :class="[showListtew ? '' : 'el-col-unfold','leftHeighe']">
          <div class="lump" style="margin-bottom: 10px">
            <el-form class="query-form" @submit.native.prevent @keyup.enter.native="getFileList(1,1)" :inline="true" size="small" ref="inputForm" :model="searchForm"
                     label-width="100px">
              <el-form-item label="资源名称" prop="name">
                <el-input size="small" maxlength="66" clearable v-model.trim="searchForm.name"
                          placeholder="请输入资源名称"></el-input>
              </el-form-item>
              <el-form-item v-if="pagetype == 'disseminate'" label=是否在资源库 prop="resourceState">
                <el-select v-model="searchForm.resourceState"
                           placeholder="请选择是否在资源库"
                           style="width: 100%"
                           clearable>
                  <el-option label="是" value="0"> </el-option>
                  <el-option label="否" value="1"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getFileList(1,1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetForm()" size="small"
                           icon="el-icon-refresh-right">
                  重置
                </el-button>
              </el-form-item>
              <el-form class="f_r">
                <div>
                  <el-button v-show="selectTab == 1 && pagetype != 'myColl' && butType != 2 && butType != 3 && pagetype != 'collectionCenter'"
                             icon="el-icon-link" @click="correlation(row)" size="small" type="primary">
                    关联资源
                  </el-button>
                  <el-button v-show="selectTab == 1 && pagetype == 'disseminate' && selectCatalogueIndex != 0" icon="el-icon-plus"
                             @click="addResources()" size="small" type="primary">
                    新增
                  </el-button>
                </div>
              </el-form>
            </el-form>
          </div>
          <div class="lump">
            <div class="flex_b_c">
              <AdvancedQuery ref="advancedQuery" :moduleId="threeModuleId"
                             @getDataList="atlGetDataList"></AdvancedQuery>
              <div>
                <el-button v-show="selectTab == 1 && pagetype != 'myColl' && butType != 2 && butType != 3 && pagetype != 'collectionCenter'"
                           icon="el-icon-delete" type="danger" @click="relieve()"
                           size="small">
                  批量解除
                </el-button>
                <el-button v-if="selectTab == 1 && pagetype == 'disseminate'"
                           type="primary" @click="addResourcePool()" icon="el-icon-receiving"
                           size="small">
                  加入资源库
                </el-button>
                <el-button
                    v-if="selectTab == 1 && pagetype == 'disseminate' && selectCatalogueIndex != 0"
                    icon="el-icon-upload2"
                    @click="batchImport()"
                    size="small">
                  批量导入
                </el-button>
                <el-button v-show="selectTab == 1 && pagetype != 'myColl' && butType != 2 && butType != 3"
                           @click="exportData()" size="small">
                  <i class="icon-piliangdaochu iconfont buIcon"/>
                  批量导出
                </el-button>
              </div>
            </div>
            <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                ref="multipleTable"
                :height="(butType == 2 || butType == 3) ? 'calc(100vh - 419px)' : 'calc(100vh - 359px)'"
                :row-key="'library_id'"
                @selection-change="selectionChangeHandle"
                class="table">
              <el-table-column type="selection" :reserve-selection="true"
                               width="55"></el-table-column>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="thumb_path" width="110" label="缩略图">
                <template slot-scope="scope">
                  <el-image class="infoImg" :src="scope.row.thumb_path" fit="contain">
                    <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('@/assets/img/thumbnail.png')"></el-image>
                    <el-image slot="error" fit="contain" class="infoImg" :src="require('@/assets/img/thumbnail.png')"></el-image>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                  show-overflow-tooltip
                  prop="resource_name"
                  label="资源名称">
              </el-table-column>
              <el-table-column prop="resource_type" label="资源类型" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("resource_type", scope.row.resource_type, '-') }}
                </template>
              </el-table-column>
              <el-table-column v-if="pagetype == 'disseminate'" prop="stock_state" width="100px" label="是否在资源库"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.stock_state == 0 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="resource_foshot" show-overflow-tooltip label="资源简称">
              </el-table-column>
              <el-table-column prop="resource_desc" label="资源描述" show-overflow-tooltip/>
              <el-table-column
                  prop="resource_title" width="120"
                  show-overflow-tooltip
                  label="主题标题 "></el-table-column>
              <el-table-column
                  prop="resource_source" width="120"
                  show-overflow-tooltip
                  label="资源来源 ">
              </el-table-column>
              <el-table-column
                  prop="written_time" width="120"
                  show-overflow-tooltip
                  label="成文时间">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="collection_name"
                               label="藏品名称"></el-table-column>
              <el-table-column show-overflow-tooltip prop="file_count"
                               label="文件数量"></el-table-column>
              <el-table-column show-overflow-tooltip prop="file_size"
                               label="文件大小"></el-table-column>
              <el-table-column
                  prop="createUserName" width="120"
                  show-overflow-tooltip
                  label="关联人">
              </el-table-column>
              <el-table-column
                  prop="create_date" width="120"
                  show-overflow-tooltip
                  label="关联时间">
              </el-table-column>
              <el-table-column v-if="selectTab === '2'"
                               prop="delete_user" width="120"
                               show-overflow-tooltip
                               label="解除人">
              </el-table-column>
              <el-table-column v-if="selectTab === '2'"
                               prop="delete_date" width="120"
                               show-overflow-tooltip
                               label="解除时间">
              </el-table-column>
              <el-table-column fixed="right" width="120" label="操作">
                <template slot-scope="scope">
                  <div class="text_center">
                    <el-button v-if="selectTab == 1 && pagetype == 'disseminate' && scope.row.stock_state == 1"
                               type="text" size="small"
                               @click="addResourcePool(scope.row)">加入资源库
                    </el-button>
                    <el-button type="text" size="small" @click="view(scope.row)">资源详情
                    </el-button>
                    <el-button v-if="selectTab == 1 && pagetype == 'disseminate' && scope.row.stock_state == 1"
                               type="text" size="small"
                               @click="modify(scope.row)">修改
                    </el-button>
                    <el-button
                        v-show="selectTab == 1 && pagetype != 'myColl' && butType != 2 && butType != 3 && pagetype != 'collectionCenter'"
                        type="text" size="small" @click="relieve(scope.row)">解除
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="text_center">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page="current"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="size"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="text_center" style="margin-top: 15px" v-if="butType == 2 || butType == 3">
        <el-button size="small" v-if="!see" type="primary" @click="switchData(1)">上一条
        </el-button>
        <el-button size="small" v-if="!see" type="primary" @click="switchData(0)">下一条
        </el-button>
        <el-button size="small" v-if="inputForm.publishState == '1'" type="primary"
                   @click="through('2')">通过
        </el-button>
        <el-button size="small" v-if="inputForm.publishState == '1'" type="danger"
                   @click="through('3')">驳回
        </el-button>
        <el-button size="small" @click="handleClose()">关闭</el-button>
      </div>
      <correlation :pagetype="pagetype" ref="correLation" @refresh="getFileList('',1)"></correlation>
      <div class="detailBox" v-if="butType == 2 || butType == 3">
        <div :class="['showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
             @click="showList = !showList"></div>
        <div class="modelBox" v-show="showList">
          <el-form size="small" :model="inputForm" ref="inputForm" label-width="120px">
            <el-form-item label="专题库名称" prop="thematicName">
              <el-input v-model.trim="inputForm.thematicName" disabled clearable maxlength="20"
                        placeholder="专题库名称"></el-input>
            </el-form-item>
            <el-form-item label="资源数量" prop="resourceNum">
              <el-input v-model.trim="inputForm.resourceNum" disabled clearable maxlength="20"
                        placeholder="资源数量"></el-input>
            </el-form-item>
            <el-form-item label="是否加水印" prop="watermarkState">
              <el-radio-group v-model="inputForm.watermarkState" disabled>
                <el-radio :label="'0'">是</el-radio>
                <el-radio :label="'1'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="水印内容" prop="watermarkContent" v-if="inputForm.watermarkState == '0'">
              <el-input v-model.trim="inputForm.watermarkContent" disabled clearable maxlength="20"
                        placeholder="水印内容"></el-input>
            </el-form-item>
            <el-form-item label="对照图片集合" prop="imgList">
              <div
                  style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
              >
                <el-image
                    style="width: 100%; height: 110px;"
                    :src="inputForm.phot"
                    :fit="'cover'"
                    :preview-src-list="[inputForm.phot]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 100%; height: 110px;"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </el-form-item>
            <el-form-item label="照片说明" prop="photExplain">
              <el-input type="textarea" rows="5" resize="none" disabled
                        v-model.trim="inputForm.photExplain" maxlength="500"
                        placeholder="照片说明"
                        clearable></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!--三维新增-->
    <AddThreeForm ref="addThreeForm" @refreshDataList="getFileList('',1)"></AddThreeForm>
    <AddForm :resourceType='selectCatalogueIndex' ref="addForm" @refreshDataList="getFileList('',1)"></AddForm>

    <!--三维资源修改-->
    <EditThreeForm ref="editThreeForm" @refreshDataList="getFileList('',1)"></EditThreeForm>
    <EditForm ref="editForm" @refreshDataList="getFileList('',1)"></EditForm>

    <!--上传表格-->
    <BulkImport ref="bulkImport" @refreshDataList="getFileList('',1)" :originalId="catalogueList[selectCatalogueIndex].moduleId" :resourceType="selectCatalogueIndex"
                :resourceName="catalogueList[selectCatalogueIndex].total"></BulkImport>
    <AuditForm ref="auditForm" @handleClose="handleClose()"></AuditForm>
  </el-dialog>
</template>

<script>

import Correlation from "@/views/modules/dyyg/libraryManagement/correlation.vue";
import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
import AddThreeForm from '@/views/modules/dyyg/gather/resource/three/addThreeForm.vue'
import AddForm from "@/views/modules/dyyg/gather/resource/components/addForm.vue";
import EditThreeForm from '@/views/modules/dyyg/gather/resource/three/editThreeForm.vue'
import EditForm from '@/views/modules/dyyg/gather/resource/components/editForm.vue'
import BulkImport from "@/views/modules/dyyg/gather/resource/components/bulkImport.vue";
import AuditForm from "./auditForm.vue";

export default {
  name: "collList",
  components: {BulkImport, AddForm, Correlation, AdvancedQuery, AddThreeForm, EditThreeForm, EditForm,AuditForm},
  data() {
    return {
      title: '',
      dialogVisible: false,
      showList: false,
      showListtew:true,
      selectTab: '1',
      tabsList: [
        {
          name: '关联资源列表',
          value: '1',
        },
        {
          name: '解除关联记录',
          value: '2',
        },
      ],
      columnSrh: '',
      columnLoading: false,
      selectCatalogueIndex: 0,
      catalogueList: [
        {
          value: 0,
          total: '三维资源',
          moduleId: "946049961995272192",
        },
        {
          value: 1,
          total: '图片资源',
          moduleId: "946049994480156672",
        },
        {
          value: 2,
          total: '音频数据',
          moduleId: "958676692447002624",
        },
        {
          value: 3,
          total: '视频数据',
          moduleId: "946050361855049728",
        },
        {
          value: 4,
          total: '出版物数据',
          moduleId: "946050391827546112",
        },
        {
          value: 5,
          total: '文献数据',
          moduleId: "946050416573939712",
        },
        {
          value: 6,
          total: '文件资料',
          moduleId: "946050511168077824",
        },
        {
          value: 7,
          total: '其它资源',
          moduleId: "946050537948708864",
        },
      ],

      searchForm: {
        name: '',
        resourceState:'',
      },

      columeId: '',
      loading: false,
      dataList: [],
      dataListSelect: [],
      current: 1,
      size: 10,
      total: 0,
      searchData: {},

      threeModuleId: "946049961995272193",   // 当前请求拖拽排序数据id
      tableHead: [],

      threeType: '0',
      listType: '',
      onScene: false, // 是否正在使用场景
      dataListQuery: [], // 临时储存场景
      row: {},
      butType: '1', //1我的专题库 0公共专题库
      pagetype: '',
      inputForm: {
        id: '',
        thematicId: '',
        thematicName: '',
        resourceNum: '',
        watermarkState: '0',
        watermarkContent: '',
        phot: '',
        photExplain: '',
        publishState: "",
        suggest:"",
      },
      registrationSearch: {},
      see: false,
    }
  },
  mounted() {
    // this.getHead();
  },

  methods: {
    // type 1我的专题库 0公共专题库 2专题库公开审核
    init(type, row, searchData, homeSetOpt, pagetype, see) {
      this.butType = type
      this.row = row
      this.selectCatalogueIndex = 0
      this.selectTab= '1'
      this.dialogVisible = true
      this.pagetype = pagetype
      this.see = see
      if(this.pagetype == 'disseminate'){ //宣传报道专题库
        this.catalogueList[0] = {
          value: 0,
          total: '全部',
          moduleId: "946049961995272192",
        }
      }else {
        this.catalogueList[0] = {
          value: 0,
          total: '三维资源',
          moduleId: "946049961995272192",
        }
      }
      if (searchData) {
        this.searchData = searchData
      }
      if (homeSetOpt) {
        this.selectTab = homeSetOpt.selectTab
        this.current = homeSetOpt.current
        this.size = homeSetOpt.size
        this.searchForm = homeSetOpt.searchForm
        this.columeId = homeSetOpt.columeId
        this.row = homeSetOpt.row
        this.butType = homeSetOpt.butType
        this.pagetype = homeSetOpt.pagetype
        this.selectCatalogueIndex = homeSetOpt.selectCatalogueIndex
        sessionStorage.removeItem('homeSetOpt')
      }
      console.log('===============')
      console.log(this.row)
      this.$nextTick(() => {
        this.autoHighlight();
        this.getFileList(1, 1);
      })
      if (this.butType == 2 || this.butType == 3) {
        this.$axios(this.api.dyyg.thematiclibrarypublishGetById + this.row.id, {}, 'get').then((res) => {
          if (res.status) {
            this.inputForm.id = res.data.id
            this.inputForm.publishState = res.data.publishState
            this.inputForm.thematicId = res.data.thematicId
            this.inputForm.thematicName = this.row.thematicName
            this.inputForm.resourceNum = this.row.resourceNum
            this.inputForm.watermarkState = res.data.watermarkState
            this.inputForm.watermarkContent = res.data.watermarkContent
            this.inputForm.photExplain = res.data.photExplain
            this.inputForm.phot = res.data.phot
          }
        })
      }
    },

    //获取表头数据
    getHead() {
      this.tableHead = [];
      this.$axios(this.api.dyyg.getByOriginalId + this.threeModuleId, '', "get").then((res) => {
        if (res.status) {
          this.tableHead = res.data.filter(item => item.logicType == 0)
          this.$nextTick(() => {
            this.$refs.catalogueTable.doLayout()
          })
        }
      })
    },

    resetForm() {
      this.searchForm.name = ''
      this.searchForm.resourceState = ''
      this.onScene = false
      this.dataListQuery = []
      this.$refs.advancedQuery.addForm.sceneId = ''
      this.$nextTick(() => {
        this.onScene = false
        this.getFileList(1, 1, -1)
      })
    },
    exportData() {
      if (!this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelect.map(item => {
        if (item) {
          return item.library_id
        }
      })
      this.exportExcel(this.api.dyyg.thematiclibraryresourceExPort, ids, '专题库资源列表', 'post')
      this.getFileList('', 1)
    },

    // 获取高级查询条件
    getDataList(data) {
      if (data != null && data.sceneId != null && data.sceneId != '') {
        this.onScene = true
      } else {
        this.onScene = false
      }
      if (data != null && data.clearCurrent != null && data.clearCurrent == true) {
        this.current = 0
      }
      this.dataListQuery = data
      data.size = this.size
      data.current = this.current
      data.type = (this.pagetype == 'disseminate' && this.threeType == 0) ? '' : this.threeType
      data.thematicId = this.row.id
      data.delFlag = this.selectTab === '1' ? 0 : 1
      data.searchNumOrName = this.searchForm.name
      this.$axios(this.api.dyyg.queryResourceThematicLibrary, JSON.stringify(data), 'post').then(res => {
        if (res && res.status) {
          this.dataList = res.data.records;
          this.total = parseInt(res.data.total)
        }
      })
    },

    //获取资源列表
    //type: -1 时为重置不需要考虑高级筛选
    getFileList(type1, dividePage, type) {
      if (type1 == 1) {
        this.current = 1
      }
      if (dividePage == 1) {
        this.$refs.multipleTable.clearSelection()
      }
      if (type != -1) {
        if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
          this.getDataList(this.dataListQuery)
          this.loading = false
          return
        }
      }
      this.loading = true;
      this.dataListQuery.sceneId = ''
      this.$axios(this.api.dyyg.thematiclibraryresourceList, {
        'current': this.current,
        'size': this.size,
        'delFlag': this.selectTab === '1' ? '0' : '1',
        'resourceType': (this.pagetype == 'disseminate' && this.threeType == 0) ? '' : this.threeType,
        'thematicId': this.row.id,
        'resourceNameOrId': this.searchForm.name,
        'resourceState': this.searchForm.resourceState,
      }, 'get').then(res => {
        if (res.status) {
          this.loading = false;
          this.dataList = res.data.records;
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getFileList()
          }
        } else {
          this.$message.error('查询失败');
        }
      })
    },

    //num 2驳回 3通过
    through(num) {
      this.$refs.auditForm.init(num, this.inputForm)

      // this.$confirm(`确定${num == '2' ? '通过' : '驳回'}专题库公开发布申请?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$axios(this.api.dyyg.thematiclibrarypublishExamine, {
      //     'publishState': num,
      //     'thematicId': this.inputForm.thematicId,
      //     'id': this.inputForm.id
      //   }, 'put').then(data => {
      //     if (data.status) {
      //       this.$message.success(`${num == '2' ? '通过' : '驳回'}成功`)
      //       this.handleClose()
      //       this.metadataId = data.data.records[0].id;
      //     }
      //   })
      // })
    },

    //获取元数据id
    // getMetadataId() {
    //   this.$axios(this.api.dyyg.metaDataDirectoryList, {
    //     'resourceName': this.resourceName,
    //     'current': this.threePageNo,
    //     'size': this.threePageSize,
    //   }, 'get').then(data => {
    //     if (data.status) {
    //       this.metadataId = data.data.records[0].id;
    //     }
    //   })
    // },

    //左边目录页默认高亮第一行
    autoHighlight() {
      this.$refs.catalogueTable.setCurrentRow(this.catalogueList[this.selectCatalogueIndex])
    },

    //左边列表选择
    directorySelection(news) {
      this.onScene = false

      this.dataListQuery.queryConditions = []
      this.searchForm.name = ''
      this.selectCatalogueIndex = news.value
      if (news.value == 0) { //三维资源
        this.threeType = 0
        this.listType = 'three';
      } else if (news.value == 1) { //图片资源
        this.threeType = 1;
        this.listType = 'picture';
      } else if (news.value == 2) { //音频数据
        this.threeType = 2
        this.listType = 'audio';
      } else if (news.value == 3) { //视频数据
        this.threeType = 3
        this.listType = 'video';
      } else if (news.value == 4) { //出版物数据
        this.threeType = 4
        this.listType = 'publicationData';
      } else if (news.value == 5) { //文献数据
        this.threeType = 5
        this.listType = 'literatureData';
      } else if (news.value == 6) { //文件资料
        this.threeType = 6
        this.listType = 'document';
      } else if (news.value == 7) { //其他资源
        this.threeType = 7
        this.listType = 'other';
      }
      this.getFileList(1, 1);
      // this.getMetadataId();
    },

    // 查看
    view(row) {
      let opt = {
        current: this.current,
        size: this.size,
        searchForm: this.searchForm,
        columeId: this.columeId,
        row: this.row,
        selectTab:this.selectTab,
        butType: this.butType,
        pagetype: this.pagetype,
        selectCatalogueIndex: this.selectCatalogueIndex,
      }
      sessionStorage.setItem('homeSetOpt', JSON.stringify(opt))
      var newRow = row
      console.log(window.location)
      sessionStorage.setItem('dizhi', window.location.pathname)
      this.$router.push({
        path: '/dyyg/home/retrievedDetails',
        query: newRow
      });
    },

    // 高级查询
    atlGetDataList(data) {
      this.getDataList(data)
    },

    //解除
    relieve(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      if (row) {
        ids = [row.library_id]
      } else {
        ids = this.dataListSelect.map(item => {
          return item.library_id
        })
      }
      this.$confirm(`确定从专题库中解除对应的资源数据？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.thematiclibraryresourceRemoveById, {ids}, 'delete').then((res) => {
          if (res.status) {
            this.$message.success('解除成功')
            this.getFileList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //关联
    correlation(row) {
      this.$refs.correLation.init(row)
    },

    //新增
    addResources() {
      if (this.selectCatalogueIndex == 0) {
        this.$refs.addThreeForm.init(this.row.id);
      } else {
        this.$refs.addForm.init('', this.selectCatalogueIndex, this.row.id);
      }
    },

    //加入资源库
    addResourcePool(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      if (row) {
        ids = [row.library_id]
      } else {
        ids = this.dataListSelect.map(item => {
          return item.library_id
        })
      }
      this.$confirm(`确定将资源数据同步到资源库中？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.thematiclibraryresourceUpdateById, {ids}, 'delete').then((res) => {
          if (res.status) {
            this.$message.success('同步成功')
            this.getFileList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //批量导入
    batchImport(){
      this.$refs.bulkImport.init(this.row.id);
    },

    //修改
    modify(row) {
      console.log(row.resource_type)
      if (row.resource_type == 0) {
        this.$refs.editThreeForm.init(row);
      } else {
        console.log(row.resource_type)
        this.$refs.editForm.init(row.id, row.resource_type, this.catalogueList[this.selectCatalogueIndex].moduleId, row.collection_id, row.resource_type);
      }
    },

    // 多选
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    handleClick() {
      this.$refs.inputForm.resetFields()
      this.getFileList(1, 1);
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList(1, 1);
      }
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList('', '');
      }
    },

    // 点击栏目
    handleCurrentChange(val) {
      this.columeId = val.id
      this.resetForm()
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current == 1) {
          this.$message.error('已是第一条')
          return
        } else {
          this.registrationSearch.current--
        }
      }
      let fetchUrl = this.api.dyyg.thematiclibrarypublishList
      let methodsType = 'get'
      this.$axios(fetchUrl, this.registrationSearch, methodsType).then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.id = res.data.records[0].id
            this.init(2, res.data.records[0])
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    handleClose() {
      this.$refs.inputForm.resetFields()
      this.$emit('refresh')
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.lump {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
  border: 1px solid #E9E9E9;
  border-radius: 10px;
  padding: 20px;
}

.detailBox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;

  .el-icon-arrow-right {
    right: 300px;
  }

  .modelBox {
    padding: 15px;
    border: 1px solid #eeeeee;
    background: #ffffff;
    border-radius: 5px;
    width: 300px;
    overflow: hidden;
    height: 500px;
  }
}

.showListBtn {
  right: 0rem;
}

.infoImg{
  width: 110px;
  height: 110px;
}
</style>
