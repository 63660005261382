<template>
    <el-dialog
            title="专题库公开发布"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :model="inputForm" :rules="rules" ref="inputForm" label-width="120px">
            <el-form-item label="专题库名称" prop="thematicName">
                <el-input v-model.trim="inputForm.thematicName" disabled clearable maxlength="20"
                          placeholder="请输入专题库名称(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="资源数量" prop="resourceNum">
                <el-input v-model.trim="inputForm.resourceNum" disabled clearable maxlength="20"
                          placeholder="请输入专题库名称(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="是否加水印" prop="watermarkState">
                <el-radio-group v-model="inputForm.watermarkState">
                    <el-radio :label="'0'">是</el-radio>
                    <el-radio :label="'1'">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="水印内容" prop="watermarkContent" v-if="inputForm.watermarkState == '0'">
                <el-input v-model.trim="inputForm.watermarkContent" clearable maxlength="20"
                          placeholder="请输入水印内容(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="上传照片" prop="imgList">
                <resume-upload ref="resume-upload" :uploadNum="1" :filtButton="true"
                               :allowFiles="imgPattern"
                               @upFileDate="(data)=>getUpData(data,1)" :upDateType="'img'"></resume-upload>

                <div class="flex_l_c" style="flex-wrap: wrap">
                    <div
                        v-for="(item,index) in inputForm.imgList" :key="item.id"
                        style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                    >
                        <div class="el-icon-error" @click="deleteFile(item,index)"
                             style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                        <el-image
                            style="width: 100%; height: 110px;"
                            :src="item.netUrl"
                            :fit="'cover'"
                            :preview-src-list="[item.netUrl]"
                        >
                            <div slot="error" class="image-slot">
                                <el-image
                                    style="width: 100%; height: 110px;"
                                    :src="require('@/assets/img/default.png')"
                                    :fit="'cover'">
                                </el-image>
                            </div>
                        </el-image>
<!--                        <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">-->
<!--                            <div style="font-size: 12px;width: 100%" class="omit">{{ item.fileName }}</div>-->
<!--                        </el-tooltip>-->
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="照片说明" prop="photExplain">
                <el-input type="textarea" rows="5" resize="none"
                          v-model.trim="inputForm.photExplain" maxlength="500"
                          placeholder="请输入照片说明(限500字)"
                          clearable></el-input>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="suresData()" v-no-more-click>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
    name: "publicRelease",
    components: {resumeUpload},
    data(){
        return{
            dialogVisible:false,
            selectData:{},
            inputForm:{
                id:'',
                thematicId:'',
                thematicName:'',
                resourceNum:'',
                watermarkState:'0',
                watermarkContent:'',
                imgList:[],
                photExplain:'',
            },
            rules:{
                watermarkState: [
                    {required: true, message: '请选择是否添加水印', trigger: 'change'}
                ],
                watermarkContent: [
                    {required: true, message: '请输入水印内容', trigger: 'blur'}
                ],
                imgList: [
                    {type: 'array',required: true, message: '请上传照片', trigger: 'blur'}
                ],
            },
            imgPattern: ['JPG', 'PNG', 'JPEG']
        }
    },

    methods:{
        init(row){
            this.dialogVisible = true
            this.selectData = row;
            this.inputForm.thematicId = row.id
            this.inputForm.thematicName = row.thematicName
            this.inputForm.resourceNum = row.resourceNum
            if (row.publishState == 3){
                this.$axios(this.api.dyyg.thematiclibrarypublishGetById + row.id, {}, 'get').then((res) => {
                    if (res.status) {
                        this.inputForm.id = res.data.id
                        this.inputForm.watermarkState = res.data.watermarkState
                        this.inputForm.watermarkContent = res.data.watermarkContent
                        this.inputForm.photExplain = res.data.photExplain
                        let imgs = [
                            {
                                id:'2055564748',
                                netUrl:res.data.phot,
                                filePath:res.data.rawPhot,
                            }
                        ]
                        this.$set(this.inputForm, 'imgList', imgs)
                        this.$nextTick(() => {
                            this.$refs['resume-upload'].setOldFile(this.inputForm.imgList)
                        })

                    }
                })
            }
        },

        //获取上传文件
        getUpData(data, num) {
            this.inputForm.imgList = data.map((item, index) => {
                if (item.upDate) {
                    this.$set(item.upDate, 'fileID', item.id)
                    this.$set(item.upDate, 'fileSort', index + 1)
                    this.$set(item.upDate, 'masterView', 1)
                    this.$set(item.upDate, 'fileSize', item.origSize)
                    return item.upDate
                } else {
                    return item
                }
            })
        },

        deleteFile(row, index) {
            this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.inputForm.imgList.splice(index, 1)
                this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
            }).catch(() => {
            })
        },

        //提交
        suresData() {
            let searchData = JSON.parse(JSON.stringify(this.inputForm))
            this.$delete(searchData,'imgList')
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (!this.inputForm.id){
                        this.$axios(this.api.dyyg.thematiclibrarypublishSave, {
                            ...searchData,
                            phot:this.inputForm.imgList[0].filePath
                        }, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('公开发布成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }else {
                        this.$axios(this.api.dyyg.thematiclibrarypublishUpdateById, {
                            ...searchData,
                            phot:this.inputForm.imgList[0].filePath
                        }, 'put').then((res) => {
                            if (res.status) {
                                this.$message.success('公开发布成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        handleClose(){
            if (this.inputForm.imgList.length) {
                this.inputForm.imgList.forEach(item => {
                    this.$refs["resume-upload"].deleteFile(item.fileID ? item.fileID : item.id, '', 1)
                })
                this.inputForm.imgList = []
            }
            this.$refs.inputForm.resetFields()
            this.inputForm = {
                id:'',
                thematicName:'',
                resourceNum:'',
                watermarkState:'0',
                watermarkContent:'',
                imgList:[],
                photExplain:'',
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>